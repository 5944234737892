<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogParticipate"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">List Participant</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text v-if="dataAnswer">
          <v-container>
            <v-card class="pa-3 mb-1" v-for="item in dataAnswer" :key="item.id">
              <v-row>
                <v-col cols="8"> {{ item.nama }} </v-col>
                <!-- <v-col cols="4">
                  <div class="d-flex justify-end">
                    <v-btn
                      content="Lihat jawaban"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                      }"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </div>
                </v-col> -->
              </v-row>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "listUserParticipant",

  props: ["dialogParticipate", "dataAnswer"],
  computed: {},
  data() {
    return {
      nameCampus: "",
    };
  },
  methods: {},
};
</script>

<style></style>
