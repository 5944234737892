<template>
  <div class="_bg-default _100vh pb-3">
    <div v-if="survey" class="py-5">
      <div class="my-3">
        <h3>Participant</h3>
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="item in survey.data"
          :key="item.id"
        >
          <v-card class="radius-card" height="210" elevation="0">
            <div class="pa-2">
              <div>
                <h3 class="text-center" v-snip="2">{{ item.judul }}</h3>
              </div>
              <div class="d-flex align-center">
                <div
                  style="width: 30%; height: 100%"
                  class="d-flex justify-end align-center"
                >
                  <v-btn fab color="purple lighten-5" depressed>
                    <v-icon color="purple">mdi-finance </v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column justify-center ml-2 mt-5">
                  <div v-for="(counter, idx) in item.relationships" :key="idx">
                    <h2 v-if="counter.jawaban">
                      {{ counter.jawaban.data.length }}
                    </h2>
                  </div>
                  <p class="mt-n1 grey--text">Participant</p>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue"
                fab
                depressed
                dark
                small
                @click="
                  listPeopleParticipate(item.relationships[1].jawaban.data)
                "
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <PeopleParticipant
        v-bind:dialogParticipate="dialogParticipate"
        v-bind:dataAnswer="dataAnswer"
        @close="closeDialog()"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import PeopleParticipant from "../../components/Psycholog/Modal/peopleParticipant.vue";
export default {
  components: { PeopleParticipant },
  name: "participant",

  data() {
    return {
      currentPage: 1,
      limit: 10,
      valueDeterminate: 50,
      dialogParticipate: false,
      dataAnswer: null,
    };
  },
  computed: {
    ...mapState({
      survey: (state) => state.survey.participant,
      env: (state) => state.API_URL,
      role: (state) => state.role,
      id: (state) => state.id,
    }),
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("survey/myParticipant");
    },
    listPeopleParticipate(data) {
      this.dataAnswer = data;
      this.dialogParticipate = true;
    },
    closeDialog() {
      this.dialogParticipate = false;
    },
    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store.dispatch("survey/deleteSurvey", item.id).then(() => {
            this.fetchItem(); //if success refetch data all
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
